import React from 'react';
import {
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaLinkedin,  
} from 'react-icons/fa';
import Logo from '../websiteImages/Logo_Aliensguard_Transparent.png'

import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const SERVICE_ID = "service_00t18mx";
const TEMPLATE_ID = "template_1blno1i";
const USER_ID = "HPlMfNs_VZdQrecTj";

const Footer = () => {

  const handleOnSubmit = (e) => {

    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'You have subscribed sucessfully.',
          confirmButtonColor: '#2e51a5'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
          confirmButtonColor: '#2e51a5'
        })
      });
    e.target.reset()
  };

  return (
    <div className='w-full mx-auto py-16 px-4 flex md:flex-row justify-between flex-col gap-10 bg-blue-950 text-[#a3a3a3]'>
      <div className='flex flex-col md:ml-10'>
        <a href='/'><img src={Logo} alt='Aliens Guard' className='md:cursor-pointer w-[8rem]' /></a>
        <p className='py-4 text-sm'>
          ALIENSGAURD PRIVATE LIMITED<br />
	  (A wing of Daemon Enterprise Pte Ltd) <br />
	  Head Office, <br />
          Layout 18 Kaki Bukit Rd 3, <br />
          Entrepreneur Business Centre, <br />
          Singapore 415978 <br /> 
          Email: Consultants@aliensguard.com  <br /> 
          Phone: +6596441002/+6597585695<br /> 
        </p>
        <div className='flex justify-between lg:w-[75%] md:w-[70%] w-[40%] my-6'>
        <a href='https://www.linkedin.com/company/aliensguard'> <FaLinkedin size={20} /> </a>
        </div>
        <div className='text-sm'>
          © 2024 All rights reserved
        </div>
      </div>
              <div>
          <h6 className='uppercase font-bold text-gray-400 text-base'>Company</h6>
          <hr class="my-2 h-0.5 border-t-0 bg-[#a2d7ff] opacity-100 sm:w-1/4 w-1/2 md:w-full" />
          <ul>
            <li className='py-1 text-sm'><a href='/#company'>About Aliensguard</a></li>
            <li className='py-1 text-sm'><a href='/cases'>Case Studies</a></li>
            <li className='py-1 text-sm'><a href='/#careers'>Careers</a></li>
            <li className='py-1 text-sm'><a href='/termsofuse'>Terms of Use</a></li>
            <li className='py-1 text-sm'><a href='/legalTerms'>Legal Terms</a></li>
          </ul>
        </div>
        <div>
          <h6 className='uppercase font-bold text-gray-400 text-base'>Services</h6>
          <hr class="my-2 h-0.5 border-t-0 bg-[#a2d7ff] opacity-100  sm:w-1/4 w-1/2 md:w-full" />
          <ul>
            <li className='py-1 text-sm'><a href='/#servicesProfessional'>Professional Services</a></li>
            <li className='py-1 text-sm'><a href='/#servicesOnSite'>On-site Installation Services</a></li>
          </ul>
        </div>
        <div>
          <h6 className='uppercase font-bold text-gray-400 text-base'>Contact Us</h6>
          <hr class="my-2 h-0.5 border-t-0 bg-[#a2d7ff] opacity-100  sm:w-1/4 w-1/2 md:w-full" />
          <ul>
            <li className='py-1 text-sm'><a href='/#contact'>Sales</a></li>
            <li className='py-1 text-sm'><a href='/#partners'>Partners</a></li>
            <li className='py-1 text-sm'>Press</li>
            <li className='py-1 text-sm'><a href='/#careers'>Careers</a></li>
          </ul>
        </div>


      <div className='md:w-[20%] mr-10'>
        <h6 className='uppercase font-bold text-gray-400 text-base'>Newsletter</h6>
        <hr class="my-1 h-0.5 border-t-0 bg-[#a2d7ff] opacity-100  sm:w-1/4 w-1/2 md:w-full" />
        <form onSubmit={handleOnSubmit} className='flex flex-col space-y-4'>
          <div className=' sm:w-1/2 w-full md:w-full'>
            <p className='py-2 text-sm'>
              To get our latest details, please provide your email id below:
            </p>
            <input type='email' name="user_email" placeholder='Email address' className='ring-1 ring-gray-300 
              w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-blue-800 mt-2'></input>
            <input class="form-check-input hidden" type="radio" name="type" id="subscription" value="subscription request" checked></input>
            <button type='submit' className='inline-block rounded-xl border-2 bg-[#2e51a5] 
            md:px-4 md;py-3 sm:px-3 sm:py-2 px-2 py-1 mt-5 text-white font-semibold text-sm uppercase
            hover:border-[#2e51a5] hover:bg-white hover:text-[#2e51a5]'>
              Subscribe
            </button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default Footer;
