import React from 'react'
import Logo from '../websiteImages/Logo_Aliensguard_blue.png'

const About = () => {
  return (
    <div className='w-full h-full flex flex-row item-center bg-white py-16 px-4' id='company'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        
        <div className='flex flex-col justify-center order-2 md:order-1'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 md:mb-10 mb-5 mt-10'>About Aliensguard</h1>
          <p className='md:text-xl sm:text-lg text:base md:mt-10 mt-5'>
          Aliensguard develops and sells cybersecurity products which supports both unidirectional
           and bidirectional data transfer. Data diodes act as checkpoint to secure data availability
            across the networks. Aliensguard have promise to deliver unhackable devices to replace 
            software based firewall.
          </p>
          
        </div>
        <img className='md:w-[500px] w-[300px] mx-auto my-auto order-1 md:order-2' loading='lazy' src={Logo} alt='/' />
      </div>
    </div>
  )
}

export default About